// import {
//   EmailShareButton,
//   FacebookShareButton,
//   TwitterShareButton,
//   WhatsappShareButton
// } from "react-share";
import logo from './assets/icon-light.svg'
import headerBG from './assets/header-blue.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header" style={{ 
          backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.4)), url(${headerBG})`
        }}>
        <a href="https://sonsofprophets.co">
          <img src={logo} className="App-logo" alt="logo" />
        </a>
        <h5>Podcast Coming Soon</h5>
        <a href="https://sonsofprophets.co" className="text-link">
          <p>Return to Sons of Prophets</p>
        </a>
      </header>
    </div>
  );
}

export default App;
